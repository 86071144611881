<template>
  <span />
</template>
<script>
  const config = require('@/config')
  export default {
    name: 'Logout',
    data: () => ({
    }),
    created: function () {
      console.log('in logout')
      localStorage.removeItem(config.authToken)
      this.$router.push({ path: '/login' })
    },
  }
</script>
